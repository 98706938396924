import React, { useEffect, useState } from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const EnvironmentSetup = () => {
  const [type, setType] = useState(null)
  useEffect(() => {
    const searchParam = new URLSearchParams(window.location.search);
    setType(searchParam.get("type"));
  }, []);
  return (
    <LayoutComponent>
      <h2>Configure Jenkins or GitHub Actions with GAINSS for Automation</h2>
      <div className="testimonials">
        <h5>Note</h5>
        <p>
          You must be a {type === "tool" ? "organization admin" : "site admin"}{" "}
          or project admin to configure the automation execution tool for your
          project in GAINSS.
        </p>
      </div>
      <div>
        <p>
          Follow the steps below to set up and configure the tools for running
          automation tests directly from GAINSS:
        </p>
        <ul>
          <li>
            Navigate to{" "}
            {type === "tool" ? (
              <>
                <strong>Configurations</strong> in the project sidebar. Under
                the Automation sections, click on <strong>Integration</strong>
              </>
            ) : (
              <strong>Project Settings &gt; Integrations</strong>
            )}
          </li>
          <li>
            Select <strong>Execution Tool</strong>. Choose either Jenkins or
            GitHub Actions as your execution tool.
          </li>
          <li>After selecting the tool, provide configuration details.</li>
          <li>
            If you selected Jenkins, enter:
            <ul>
              <li>
                <strong>Jenkins URL</strong>
              </li>
              <li>
                <strong>Username</strong>
              </li>
              <li>
                <strong>Auth Token</strong>
              </li>
            </ul>
            <br />
            If you selected GitHub Actions, enter:
            <ul>
              <li>
                <strong>Github Repo URL</strong>
              </li>
              <li>
                <strong>Username</strong>
              </li>
              <li>
                <strong>Auth Token(PAT)</strong>
              </li>
            </ul>
          </li>
          <li>
            Click on <strong>Save</strong> to verify the details and finish
            configuration.
          </li>
        </ul>
      </div>
    </LayoutComponent>
  )
}

export default EnvironmentSetup
